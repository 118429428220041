import { Injectable } from "@angular/core";
import { AppService } from "../../../../../@core/service/App.Service";


@Injectable({
    providedIn: "root",
  })
export class smstudentpolicyservice extends AppService<any> {

    constructor() {
        super('mst_SMStudentPolicy');
    }
 
    
    async getstdEntityPolicy() {
        var entityid = Number(localStorage.getItem("selectedEntity"));
        const result = await this.get('stdEntityPolicyAsync', [entityid]);
        return result;
    }
    async getstdEntityPolicyforIntegrationform(entityid:any) {
        const result = await this.get('stdEntityPolicyAsync', [entityid]);
        return result;
    }
    async getstdEntityPolicyClientWise() {        
        const result = await this.get('stdEntityPolicyClientWise');
        return result;
    }
}
