import { Routes } from "@angular/router";
import "@angular/compiler";
import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";
import { AuthGuard } from "../app/@core/helper/auth.guard";
import { UtComponent } from "./layouts/ut/ut.component";
export const Approutes: Routes = [
  {
    path: "",
    component: FullComponent,
    children: [
      {
        path: "",
        redirectTo: "/authentication/GatewayProtocol",
        pathMatch: "full",
      },
      {
        canActivate: [AuthGuard],
        path: "menucomponent",
        loadChildren: () =>
          import("../app/layouts/menu-component/menu-component.module").then(
            (m) => m.MenuComponentModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "reportViewer",
        loadChildren: () =>
          import(
            "../app/layouts/dcs-report-viewer/dcs-report-viewer.module"
          ).then((m) => m.DcsReportViewerModule),
      },
      {
        canActivate: [AuthGuard],
        path: "Dashboard",
        loadChildren: () =>
          import("./dashboards/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "SystemSettings",
        loadChildren: () =>
          import("../app/modules/Administration/administration.module").then(
            (m) => m.AdministrationModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "EntitySetup",
        loadChildren: () =>
          import("../app/modules/Orgnization/organization-module").then(
            (m) => m.OrganizationModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "FrontDesk",
        loadChildren: () =>
          import("../app/modules/Front-Office/front-office.module").then(
            (m) => m.FrontOfficeModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "AccountPayable",
        loadChildren: () =>
          import("../app/modules/Account-Payable/account-payable.module").then(
            (m) => m.AccountPayableModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "CashManagement",
        loadChildren: () =>
          import("../app/modules/Cash-Management/cash-management.module").then(
            (m) => m.CashManagementModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "Bank&Cash",
        loadChildren: () =>
          import("../app/modules/Cash-Bank/bank-management.module").then(
            (m) => m.BankManagementModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "Transport",
        loadChildren: () =>
          import("../app/modules/Transport-Management/transport.module").then(
            (m) => m.TransportModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "ItemManagement",
        loadChildren: () =>
            import("../app/modules/Item-Management/item.module").then(
            (o) => o.MainItemModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "inventory-management",
        loadChildren: () =>
          import("../app/modules/inventory-management/inventory-management.module"
          ).then(
            (o) => o.InventoryManagementModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "FixedAssets",
        loadChildren: () =>
          import("../app/modules/Fixed-Asset/fixed-asset.module").then(
            (o) => o.FixedAssetModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "AccountReceivable",
        loadChildren: () =>
          import(
            "../app/modules/Account-Receivable/Account-Receivable.module"
          ).then((o) => o.AccountReceivableModule),
      },
      {
        canActivate: [AuthGuard],
        path: "HumanResource",
        loadChildren: () =>
          import("../app/modules/Human-Resource/human-resource.module").then(
            (o) => o.MainHumanRescourceModule
          ),
      },
      {
        path: "home",
        loadChildren: () =>
          import("../app/modules/home/home/home.module").then(
            (o) => o.HomeModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "Student",
        loadChildren: () =>
          import("../app/modules/Student/student.module").then(
            (o) => o.StudentModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "GeneralLedger",
        loadChildren: () =>
          import("../app/modules/General-Ledger/General-Ledger.module").then(
            (o) => o.MainGeneralledgerModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "Library",
        loadChildren: () =>
          import("../app/modules/Library/Library.module").then(
            (o) => o.MainLibraryModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "Academics",
        loadChildren: () =>
          import("../app/modules/Academics/Academics.module").then(
            (o) => o.MainAcademicModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "RealEstate",
        loadChildren: () =>
          import("./modules/Real Estate/real-state.module").then(
            (o) => o.RealEstateModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "RentalManagement",
        loadChildren: () =>
          import("./modules/Rental Managment/RentalManagment.module").then(
            (o) => o.RentalManagmentModule
          ),
      },
      {
        path: "TaskTracking",
        loadChildren: () =>
          import("./modules/Task Tracking/task-tracking.module").then(
            (o) => o.TaskTrackingModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "RentalManagement",
        loadChildren: () =>
          import("./modules/Rental Managment/RentalManagment.module").then(
            (o) => o.RentalManagmentModule
          ),
      },
      {
        path: "Utility",
        loadChildren: () =>
          import("../app/modules/userManagement/userManagement.module").then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: "ProjectManagement",
        loadChildren: () =>
          import(
            "../app/modules/Project-Management/Project-Management.module"
          ).then((m) => m.ProjectManagementModule),
      },
      {
        path: "PurchaseManagement",
        loadChildren: () =>
          import(
            "../app/modules/Purchase-Management/Purchase-Management.module"
          ).then((m) => m.PurchaseorganizationModule),
      },
    ],
  },
  {
    path: "",
    component: BlankComponent,
    children: [
      {
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "parentsgateway",
        loadChildren: () =>
          import("./parents-gateway/parents-gateway.module").then(
            (m) => m.parentsGatewayModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: "hdr_SMApplicationschd",
        loadChildren: () =>
          import(
            "./modules/Front-Office/Admission/interview-testschedule/interview-testschedule.module"
          ).then((o) => o.InterviewTestscheduleModule)
      },
      {
        canActivate: [AuthGuard],
        path: "SMAdmApplicationtest",
        loadChildren: () =>
          import(
            "./modules/Front-Office/Admission/bulkadmissiontest/bulkadmissiontest.module"
          ).then((o) => o.BulkadmissiontestModule)
      },
      {
        canActivate: [AuthGuard],
        path: "hdr_SMApplicationInterviewschd",
        loadChildren: () =>
          import(
            "./modules/Front-Office/Admission/schedule-interview/schedule-interview.module"
          ).then((o) => o.ScheduleInterviewModule)
      },
      {
        canActivate: [AuthGuard],
        path: "hdr_SMApplicationInterviewpen",
        loadChildren: () =>
          import(
            "./modules/Front-Office/Admission/pending-interview/pending-interview-list/pending-interview-list.module"
          ).then((o) => o.PendingInterviewListModule)
      },
    ],
  },
  {
    path: "",
    component: UtComponent,
    children: [],
  },
  {
    path: "online-session",
    loadChildren: () =>
      import(
        "../app/modules/shared-modals/online-session/online-session.module"
      ).then((o) => o.OnlineSessionModule),
  },
  {
    path: "TimeTable",
    loadChildren: () =>
      import(
        "../app/modules/Academics/Course&Subject/Timetable/sm-time-table/generate-sm-timetable/generate-sm-timetable.module"
      ).then((o) => o.GenerateSmTimetableModule),
  },
  {
    path: "LessonTopicDetail",
    loadChildren: () =>
      import(
        "../app/modules/Academics/CourseandCurriculum/Curriculum/lesson-topic-detail/lesson-topic-detail.module"
      ).then((o) => o.LessonTopicDetailModule),
  },
  {
    path: "PLPInstructions",
    loadChildren: () =>
      import(
        "../app/modules/Academics/CourseandCurriculum/Curriculum/sm-plp-instruction/sm-plp-instruction.module"
      ).then((o) => o.PLPInstructionsModule),
  },
  {
    path: "QuestionnaireDetail",
    loadChildren: () =>
      import(
        "../app/modules/Academics/Assessment/Questionnaire/questionnaire-detail/questionnaire-detail.module"
      ).then((o) => o.QuestionnaireDetailModule),
  },
  {
    path: "SMfinalresultcompilation",
    loadChildren: () =>
      import(
        "../app/modules/Academics/Assessment/Examination/final-result-compilation/final-result-compilation.module"
      ).then((o) => o.FinalResultCompilationModule),
  },
  {
    path: "StudentAttendancebarode",
    loadChildren: () =>
      import(
        "../app/modules/Student/Attendence/sm-studentattendance/sm-studentattendance.module"
      ).then((o) => o.SmStudentattendanceModule),
  },
  {
    path: "hdr_SMCourseoutline",
    loadChildren: () =>
      import(
        "../app/modules/Academics/CourseandCurriculum/Curriculum/sm-courseoutlinehdr/sm-courseoutlinehdr.module"
      ).then((o) => o.SmCourseoutlinehdrModule),
  },
  {
    path: "TeacherAssgin",
    loadChildren: () =>
      import(
        "../app/modules/Academics/Course&Subject/Timetable/sm-time-table/assign-teacher/assign-teacher.module"
      ).then((o) => o.AssignTeacherModule),
  },
  {
    path: "ImportFeeCollection",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Fee Management/Transaction/feecollection/importfeecollection/importfeecollection.module"
      ).then((o) => o.ImportfeecollectionModule),
  },
   {
    path: "ImportItems",
    loadChildren: () =>
      import(
        "../app/modules/Item-Management/General/Setup/imitem/import-items/import-items.module"
      ).then((o) => o.ImportItemsModule),
  },
    {
    path: "ImportVendor",
    loadChildren: () =>
      import(
        "../app/modules/Account-Payable/General/Setup/vendor/import-vendor/import-vendor.module"
      ).then((o) => o.ImportVendorModule),
  },
  {
    path: "ImportMedia",
    loadChildren: () =>
      import(
        "../app/modules/Library/General/Setup/media/import-media/import-media.module"

      ).then((o) => o.ImportMediaModule),
  },
  {
    path: "BulkInvoices",
    loadChildren: () =>
      import(
        "../app/modules/Account-Payable/General/Invoices/bulkinvoices/bulkinvoices.module"
      ).then((o) => o.BulkinvoicesModule),
  },
  {
    path: "ImportCustomers",
    loadChildren: () =>
      import(
        "./modules/Account-Receivable/Customer Invoicing/Setup/customer/importcustomer/importcustomer.module"
      ).then((o) => o.ImportcustomerModule),
  },
  {
    path: "hdr_SMStudentbulktransfer",
    loadChildren: () =>
      import(
        "../app/modules/Student/Transfer-Withdrawal/bulkstudenttransfer/bulkstudenttransfer.module"
      ).then((o) => o.BulkstudenttransferModule),
  },
  {
    path: "hdr_SMStudentwithdrawalBulk",
    loadChildren: () =>
      import(
        "../app/modules/Student/Transfer-Withdrawal/bulkstudentwithdraw/bulkstudentwithdraw.module"
      ).then((o) => o.BulkstudentwithdrawModule)
  },
  {
    path: "hdr_SMFeeStructureBulkController",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Fee Management/Transaction/bulkfeestructure/bulkfeestructure.module"
      ).then((o) => o.BulkfeestructureModule)
  },
  {
    path: "hdr_bunchposting",
    loadChildren: () =>
      import(
        "../app/modules/General-Ledger/Transaction/fijournalbunchposting/fijournalbunchposting.module"
      ).then((o) => o.FijournalbunchpostingModule),
  },
  {
    path: "Bulkcertificate",
    loadChildren: () =>
      import(
        "../app/modules/Front-Office/General/Certificate/bulk-certifiate/bulk-certifiate.module"
      ).then((o) => o.BulkCertifiateModule),
  },
  {
    path: "hdr_FIPDCClearance",
    loadChildren: () =>
      import(
        "../app/modules/Cash-Bank/Bank/Setup/pdc-clearence/pdc-clearence.module"
      ).then((o) => o.PdcClearenceModule),
  }, {
    path: "SyllabusPlanDetail",
    loadChildren: () =>
      import(
        "../app/modules/Academics/CourseandCurriculum/Curriculum/syllabus-plainning/syllabus-plainning-detail/syllabus-plainning-detail.module"
      ).then((o) => o.SyllabusPlainningDetailModule),
  },
  {

    path: "SyllabusPlanDetailAdditional",
    loadChildren: () =>
      import(
        "../app/modules/Academics/CourseandCurriculum/Curriculum/syllabus-plainning/syllabus-plainning-additional/syllabus-plainning-additional.module"
      ).then((o) => o.SyllabusPlainningAdditionalModule),
  },
  {

    path: "SyllabusPlanDetailAdditionaltree",
    loadChildren: () =>
      import(
        "../app/modules/Academics/CourseandCurriculum/Curriculum/syllabus-plainning/syllabus-plainning-additional-tree/syllabus-plainning-additional-tree.module"
      ).then((o) => o.SyllabusPlainningAdditionalTreeModule),
  },
  {
    path: "AdmissionPlainning",
    loadChildren: () =>
      import(
        "../app/modules/Front-Office/Admission/smbatch/addmission-plainning/addmission-plainning.module"
      ).then((o) => o.AddmissionPlainningModule),
  },
  {
    path: "hdr_SMChangeAssignment",
    loadChildren: () =>
      import(
        "../app/modules/Academics/Course&Subject/Timetable/change-assignment/change-assignment.module"
      ).then((o) => o.ChangeAssignmentModule),
  },
  {
    path: "EmployeeAttendancebarcode",
    loadChildren: () =>
      import(
        "../app/modules/Human-Resource/Time & Attendance/Attendance/employee-attendance-barcode/employee-attendance-barcode.module"
      ).then((o) => o.EmployeeAttendanceBarcodeModule),
  },
  {
    path: "trn_HRdailyattendancedata",
    loadChildren: () =>
      import(
        "../app/modules/Human-Resource/Time & Attendance/Attendance-Procrss/employee-daily-attendance-data/employee-daily-attendance-data.module"
      ).then((o) => o.EmployeeDailyAttendanceDataModule),
  },
  {
    path: "trn_HRMonthlyAttendance",
    loadChildren: () =>
      import(
        "../app/modules/Human-Resource/Time & Attendance/Attendance-Procrss/employee-monthly-attendance-data/employee-monthly-attendance-data.module"
      ).then((o) => o.EmployeeMonthlyAttendanceDataModule),
  },
  {
    path: "trn_SMSyllabusplanningTeacher",
    loadChildren: () =>
      import(
        "../app/modules/Academics/CourseandCurriculum/Curriculum/syllabus-plainning/syllabus-planning-teacher/syllabus-planning-teacher.module"
      ).then((o) => o.SyllabusPlainningTeacherModule),
  },
  {
    path: "hdr_SMAdmAttendance",
    loadChildren: () =>
      import(
        "../app/modules/Front-Office/Admission/Admission-Attendance/Admission-AttendanceList/Admission-AttendanceList.module"
      ).then((o) => o.AdmissionAttendanceListModule),
  },
  {
    path: "IntegratedEnquiryComp",
    loadChildren: () =>
      import(
        "../app/shared/Integratedable/Enquiry-Form/Enquiry-Form.module"
      ).then((o) => o.EnquiryFormmodule),
  },
  {
    path: "IntegratedRegistrationComp",
    loadChildren: () =>
      import(
        "../app/shared/Integratedable/Registration-Form/Registration-Form.module"
      ).then((o) => o.RegistrationFormmodule),
  },
  {
    path: "WFWorkBench",
    loadChildren: () =>
      import(
        "../app/workbench/workbench.module"
      ).then((o) => o.WorkBenchModule),
  },
  {
    path: "TopicDelivery",
    loadChildren: () =>
      import(
        "../app/modules/Academics/CourseandCurriculum/Curriculum/topic-delivery/topic-delivery.module"
      ).then((o) => o.TopicDeliveryModule),
  },
  {
    path: "TopicDeliveryDetail",
    loadChildren: () =>
      import(
        "../app/modules/Academics/CourseandCurriculum/Curriculum/topic-delivery/topic-delivery-detail/topic-delivery-detail.module"
      ).then((o) => o.TopicDeliveryDetailModule),
  },
  {
    path: "ParentUnderTakingBulk",
    loadChildren: () =>
      import(
        "../app/modules/Front-Office/Admission/parents-undertaking/parent-undertaking-bulk/parent-undertaking-bulk.module"
      ).then((o) => o.ParentUndertakingBulkModule),
  },
  {
    path: "hdr_EPSalary",
    loadChildren: () =>
      import(
        "../app/modules/Human-Resource/Payroll/Transaction/payroll-process/payroll-process.module"
      ).then((o) => o.PayrollProcessModule),
  },
  {
    path: "hdr_EPSalary_Detail",
    loadChildren: () =>
      import(
        "../app/modules/Human-Resource/Payroll/Transaction/payroll-process/payroll-process-detail/payroll-process-detail.module"
      ).then((o) => o.PayrollProcessDetailModule),
  },
  {

    path: "hdr_SMFeechallanVoid",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Fee Management/Transaction/bulkchallanvoid/bulkchallanvoid.module"
      ).then((o) => o.BulkchallanvoidModule),
  },
  {
    path: "hdr_SMbulkreversecollection",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Fee Management/Transaction/bulkreversecollection/bulkreversecollection.module"
      ).then((o) => o.BulkreversecollectionModule),
  },
  {
    path: "hdr_SMStudentcocfee",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Fee Management/Setup/bulkstudentcocfee/bulkstudentcocfee.module"
      ).then((o) => o.BulkstudentcocfeeModule),
  },
  {
    path: "hdr_SMStudentdiscount",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Fee Management/Setup/bulkstudentdiscount/bulkstudentdiscount.module"
      ).then((o) => o.BulkstudentdiscountModule),
  },
  {

    path: "Questionbank",
    loadChildren: () =>
      import(
        "../app/modules/Academics/Assessment/Questionnaire/questionNewwizard/QuestionnaireNewWizard.module"
      ).then((o) => o.QuestionnaireNewWizardModule),
  },
  {

    path: "hdr_SMPATarbiyah",
    loadChildren: () =>
      import(
        "../app/modules/Student/Personal-Assessment/tarbiya/tarbiya.module"
      ).then((o) => o.TarbiyaModule),
  },
  {

    path: "hdr_SMHifz",
    loadChildren: () =>
      import(
        "../app/modules/Academics/Course&Subject/hifz/hifz-para/hifz-para-modal/hifz-para-modal.module"
      ).then((o) => o.HifzParaModalModule),
  },
  
  {
    path: "ImportCustomer",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Customer Invoicing/Setup/customer/importcustomer/importcustomer.module"
      ).then((o) => o.ImportcustomerModule),
  },
  {
    path: "trn_HRManualMonthlyAttendance",
    loadChildren: () =>
      import(
        "../app/modules/Human-Resource/Time & Attendance/Attendance-Procrss/manual-monthly-attendance/manual-monthly-attendance.module"
      ).then((o) => o.ManualMonthlyAttendanceModule),
  },
  {
    path: "RefundOpeningUpload",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Fee Management/Transaction/refundopeningupload/refundopeningupload.module"
      ).then((o) => o.RefundopeninguploadModule),
  },
  {
    path: "BulkRefundRequest",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Fee Management/Transaction/bulkrefundrequest/bulkrefundrequest.module"
      ).then((o) => o.BulkrefundrequestModule),
  },
  {
    path: "BulkRefund",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Fee Management/Transaction/bulkrefund/bulkrefund.module"
      ).then((o) => o.BulkrefundModule),
  },
  {
    path: "hdr_SMPLPAssignSubject",
    loadChildren: () =>
      import(
        "../app/modules/Academics/CourseandCurriculum/Curriculum/sm-assign-subjects/sm-assign-subjects-modal/sm-assign-subjects.module"
      ).then((o) => o.AssignSubjectsModule),
  },
  {
    path: "Result",
    loadChildren: () =>
      import(
       // "../app/modules/Account-Payable/General/Setup/vendor/import-vendor/import-vendor.module"
        "../app/modules/Academics/Assessment/Examination/sm-topicassessment/sm-topicassessmentresult/sm-topicassessmentresult.module"
      ).then((o) => o.SmTopicassessmentresultModule),
  },
  {
    path: "BulkAssessmentPolicy",
    loadChildren: () =>
      import(
        "../app/modules/Academics/Course&Subject/SM-Course/bulk-assessment-policy/bulk-assessment-policy.module"
      ).then((o) => o.BulkAssessmentPolicyModule),
  },
  {
    path: "hdr_ARFineExempt",
    loadChildren: () =>
      import(
        "../app/modules/Account-Receivable/Fee Management/Transaction/fine-exempt/fine-exempt.module"
      ).then((o) => o.FineExemptModule),
  },
  {
    path: "hdr_ImportData",
    loadChildren: () =>
      import(
        "../app/modules/Administration/setup/import-data-template/import-data-template.module"
      ).then((o) => o.ImportDataTemplateModule),
  },
  {
    path: "**",
    redirectTo: "/authentication/404",
  },
];